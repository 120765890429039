/**
 * Our grid element
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import * as styles from './grid.module.scss';
import PropTypes from 'prop-types';
import PeritusLink, {PeritusLinkProps} from '../../components/PeritusLink';
import ResponsiveImage, {ResponsiveImageProps} from '../../components/ResponsiveImage';
import Block from 'components/Block';
import TriangleIcon from '../../images/right-arrow.inline.svg';

const Grid = ({elements, ...props}) => (
    <Block className={styles.grid} {...props}>
        {
            elements && elements.map((element, key) => {
                const {
                    link,
                    image,
                } = element;

                return (
                    <PeritusLink key={key} className={styles.element} {...link}>
                        <h3 className={styles.title}>
                            <div className={styles.triangle}><TriangleIcon className={styles.triangleIcon}/></div>
                            <span className={styles.titleInner}>{link.linktext}</span>
                        </h3>
                        <div className={styles.imageContainer}>
                            <ResponsiveImage className={styles.image} image={image}/>
                        </div>
                    </PeritusLink>
                );
            })
        }
    </Block>
);

Grid.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.shape(PeritusLinkProps).isRequired,
        image: PropTypes.shape(ResponsiveImageProps).isRequired,
    })).isRequired,
};

export default Grid;
